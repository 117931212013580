<template>
  <ion-page>
    <Header title="Kebijakan Pribadi" />
    <ion-content :fullscreen="true">
      <div class="container">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem earum, perferendis nisi delectus aut pariatur, nesciunt dignissimos magni ea blanditiis quo doloremque deleniti odit! Natus provident aliquam vero iure porro?
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import Header from '@/components/headerPage'
export default {
  components: {
    IonContent,
    IonPage,
    Header
  }
}
</script>

<style>

</style>
